import React,{ useEffect, useState } from 'react';

const HoldersZone = ({ 
    walletConnected, 
    address, 
    setWalletConnected, 
    setAddress, 
    loadingOwnedInscriptions,
    loadingLatestMints,
    loadingcollectionStats, 
    wo7msInscriptions, 
    latestMints, 
    setModalOpen,
    collectionStats
}) => {
    const REACT_APP_BOUNTIES_LIST = process.env.REACT_APP_BOUNTIES_LIST.split(',').map(String);;
    const [activeTab, setActiveTab] = useState('your-inscriptions');
    const [isAtTop, setIsAtTop] = useState(true);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleScroll = () => {
        setIsAtTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    // New state for filtering
    const [selectedTraits, setSelectedTraits] = useState({
        trait1: '',
        trait2: '',
        trait3: '',
        trait4: '',
    });

    // Function to handle trait change
    const handleTraitChange = (e) => {
        setSelectedTraits({
            ...selectedTraits,
            [e.target.name]: e.target.value,
        });
    };

    // Function to reset filters
    const resetFilters = () => {
        setSelectedTraits({
            trait1: '',
            trait2: '',
            trait3: '',
            trait4: '',
        });
    };

// Extract unique trait values
const getUniqueTraitValues = (index) => {
    const values = new Set();
    latestMints.forEach(inscription => {
        const value = inscription.metadata.metadata.attributes[index]?.value;
        if (value) {
            values.add(value);
        }
    });
    return Array.from(values);
};

    // Get trait types
    const getTraitType = (index) => {
        return latestMints[0]?.metadata.metadata.attributes[index]?.trait_type || '';
    };

    // Filter latest mints based on selected traits
    const filteredLatestMints = latestMints.filter(inscription => {
        const attributes = inscription.metadata.metadata.attributes;
        return (
            (!selectedTraits.trait1 || attributes[0].value === selectedTraits.trait1) &&
            (!selectedTraits.trait2 || attributes[1].value === selectedTraits.trait2) &&
            (!selectedTraits.trait3 || attributes[2].value === selectedTraits.trait3) &&
            (!selectedTraits.trait4 || attributes[3].value === selectedTraits.trait4)
        );
    });

    return (
        <div className="flex flex-col items-center text-center justify-start min-h-screen p-6 border-2 border-lime-500" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
            {walletConnected ? (
                <>
                <div className="flex flex-col">
                    <h2 className="text-xl text-white">Connected with</h2>
                    <div className="ml-1 text-white text-sm sm:text-base break-words" style={{ fontFamily: 'Inter, sans-serif' }}>{address.slice(0,30)}...</div>
                    <div className="pl-4 text-3xl text-white cursor-pointer hover:text-red-800 " onClick={() => { setWalletConnected(false); setAddress(''); }}>Disconnect</div>
                    </div>
                    <h1 className="text-5xl font-bold text-lime-500 mt-4">WO7MS Bounties Checker</h1>
                    <div className="mb-5 mt-10">
                        <button
                            onClick={() => setActiveTab('your-inscriptions')}
                            className={`text-4xl px-4 py-2 mx-2 hover:text-lime-700 cursor-pointer ${activeTab === 'your-inscriptions' ? 'text-lime-500 border-solid border-2 border-white' : 'text-lime-600 border-solid border-2 border-lime-800'}`}
                        >
                            Your WO7MS
                        </button>
                        <button
                            onClick={() => setActiveTab('latest-mints')}
                            className={`text-4xl px-4 py-2 mx-2 hover:text-lime-700 cursor-pointer ${activeTab === 'latest-mints' ? 'text-lime-500 border-solid border-2 border-white' : 'text-lime-600 border-solid border-2 border-lime-800'}`}
                        >
                            Minted WO7MS (Bounties shown)
                        </button>
                    </div>
                  {/* Filter Controls */}
                  {activeTab === 'latest-mints' && (
                        <div className="mb-5 bg-black pt-2 pb-2 pl-2 pr-2 border-solid border-2 border-lime-600">
                            <h3 className="text-xl text-white">Filter by Traits:</h3>
                            {['trait1', 'trait2', 'trait3', 'trait4'].map((trait, index) => (
                                <select 
                                    key={index} 
                                    name={trait} 
                                    value={selectedTraits[trait]} // Bind the select to the state
                                    onChange={handleTraitChange} 
                                    className="mx-2 p-2 cursor-pointer border-solid border-2 border-lime-600 bg-black text-white"
                                >
                                    <option value="">{getTraitType(index)}</option>
                                    {getUniqueTraitValues(index).map((value, idx) => (
                                        <option key={idx} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                            ))}
                            <button onClick={resetFilters} className="mt-4 border-solid border-2 border-lime-600 text-lime-600 px-4 py-2 rounded hover:text-lime-900 hover:border-lime-900">
                                Reset Filters
                            </button>
                        </div>
                    )}
                    {loadingLatestMints || loadingOwnedInscriptions || loadingcollectionStats? (
                        <p className="text-lg text-white">Loading...</p>
                    ) : (activeTab === 'your-inscriptions' && wo7msInscriptions.length > 0) ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {wo7msInscriptions.map(inscription => {
                                 const extractedNumber = inscription.metadata.metadata.name.match(/\d+/g)?.[0];
                                 const isBounty = REACT_APP_BOUNTIES_LIST.includes(extractedNumber);
                                
                                return (
                                    <div key={inscription.metadata.metadata.name} title={"Click for more details on Asteroid"}  onClick={() => window.open(`https://asteroidprotocol.io/app/inscription/${inscription.transaction.hash}`, '_blank')}
                                     className=" cursor-pointer relative hover:scale-[1.02] border text-lime-600 text-xl border-lime-800 rounded-lg p-4 bg-lime-900 shadow-md">
                                        <div className={`inset-0 ${isBounty ? 'bg-lime-200' : 'bg-black-600'} opacity-75 flex items-center justify-center text-${isBounty ? 'black' : 'white'} font-bold rounded-lg`}>
                                            {isBounty ? 'BOUNTY' : 'NO BOUNTY'}
                                        </div>
                                        <img 
                                            src={inscription.content_path} 
                                            className="w-full h-auto rounded-md" 
                                            alt="Wo7msCard" 
                                        />
                                        <h4 className="font-bold text-2xl text-white pt-2" style={{ fontFamily: 'Inter, sans-serif' }}>{inscription.metadata.metadata.name}</h4>
                                        <p className="text-xl text-white" style={{ fontFamily: 'Inter, sans-serif' }}><strong>Rarity:</strong> {inscription.rarity.rarity_rank ? inscription.rarity.rarity_rank : '...'} / {collectionStats.supply}</p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[0].trait_type} :{' '}{inscription.metadata.metadata.attributes[0].value} </p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[1].trait_type} :{' '}{inscription.metadata.metadata.attributes[1].value}</p>        
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[2].trait_type} :{' '}{inscription.metadata.metadata.attributes[2].value} </p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[3].trait_type} :{' '}{inscription.metadata.metadata.attributes[3].value} </p>
                                    </div>
                                );
                            })}
                        </div>
                        
                    ) : ((activeTab === 'latest-mints' && wo7msInscriptions.length > 0)||(activeTab === 'latest-mints' && address === "cosmos12hme9yhzrjmknhhug5k0n5t3vfuezwjyz8ryhp")) ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {filteredLatestMints.map(inscription => {
                                 const extractedNumber = inscription.metadata.metadata.name.match(/\d+/g)?.[0];
                                 const isBounty = REACT_APP_BOUNTIES_LIST.includes(extractedNumber);
                                return (
                                    <div key={inscription.metadata.metadata.name} onClick={() => window.open(`https://asteroidprotocol.io/app/inscription/${inscription.transaction.hash}`, '_blank')} 
                                    title={"Click for more details on Asteroid"} className="cursor-pointer relative hover:scale-[1.02] border text-lime-600 text-xl border-lime-800 rounded-lg p-4 text-center bg-lime-900 shadow-md">
                                        <div className={`inset-0 ${isBounty ? 'bg-lime-200' : 'bg-black-600'} opacity-75 flex items-center justify-center text-${isBounty ? 'black' : 'white'} font-bold rounded-lg`}>
                                            {isBounty ? 'BOUNTY' : 'NO BOUNTY'}
                                        </div>
                                        <img 
                                            src={inscription.content_path} 
                                            className="w-full h-auto rounded-md cursor-pointer" 
                                            alt="Latest Mint Card" 
                                        />
                                        <h4 className="font-bold text-2xl text-white pt-2" style={{ fontFamily: 'Inter, sans-serif' }}>{inscription.metadata.metadata.name}</h4>
                                        <p className="text-xl text-white" style={{ fontFamily: 'Inter, sans-serif' }}><strong>Rarity: {inscription.rarity.rarity_rank ? `${inscription.rarity.rarity_rank} / ${collectionStats.supply}` : '...'}</strong></p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[0].trait_type} :{' '}{inscription.metadata.metadata.attributes[0].value} </p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[1].trait_type} :{' '}{inscription.metadata.metadata.attributes[1].value}</p>        
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[2].trait_type} :{' '}{inscription.metadata.metadata.attributes[2].value} </p>
                                        <p className="text-xl text-lime-600" style={{ fontFamily: 'Pixelify Sans, sans-serif'  }}>{inscription.metadata.metadata.attributes[3].trait_type} :{' '}{inscription.metadata.metadata.attributes[3].value} </p>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-red-800 text-4xl">You do not hold any "WO7MS" inscription.</h1>
                            <h1 className="text-white text-4xl">GO MINT ONE NOW</h1>
                            <span className="animate-[pulse_1s_infinite] text-lime-500 text-2xl">CLICK ON THE SCIENTIST</span>
                            <img className="cursor-pointer" title="Please click me" alt="madwait" src="./madWait.gif" onClick={() => window.open('https://asteroidprotocol.io/app/launchpad/REALWO7MS', '_blank')} />
                        </div>
                    )}
                </>
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <p className="text-lime-500 text-4xl mb-4">Only holders can access this zone</p>
                    <p className="text-white text-2xl mb-4">Let see what you got</p>
                    <p className="text-lime-500 text-4xl mb-4">⬇️</p>
                    <button onClick={() => setModalOpen(true)} title="Just an offline connection to see what you got ;)" className="text-lime-600 px-4 py-2 lg border-solid border-2 border-lime-900 hover:bg-lime-900 cursor-pointer">
                        CONNECT WALLET
                    </button>
                </div>
            )}
            {walletConnected ? (
            <div className="flex justify-center w-full pl-16 pb-6 mt-6">
                <img 
                    src="./madlearnmore.gif" 
                    alt="Scientist Learn more" 
                    className="cursor-pointer max-w-64 md:max-w-80 hover:scale-[1.05] hover:ml-1 pb-3" 
                    title="Learn more about Asteroid Protocol" 
                    onClick={() => window.open('https://docs.asteroidprotocol.io/asteroid-protocol-docs/general-overview', '_blank')}
                />
            </div>) : (<div></div>)}
            {/* Back to Top Button */}
            {!isAtTop && (
                <h1 
                className="fixed bottom-2 md:bottom-10  bg-black right-10 text-2xl text-white rounded-full p-3 shadow-lg hover:bg-lime-900 transition 
                 rounded-full border-2 border-solid border-lime-600 cursor-pointer"
                onClick={scrollToTop}
                title="Back to Top"
            >
                ↑
            </h1>
            )}
        </div>
    );
};

export default HoldersZone;
