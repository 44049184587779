import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GraphQLClient, gql } from 'graphql-request';
import Navbar from './NavBar';
import Wo7ms from './Wo7ms';
import MintRafflePrizes from './MintRafflePrizes';
import MintBounties from './MintBounties';
import HoldersZone from './HoldersZone';
import { Route, Routes, useLocation } from 'react-router-dom';

const WalletConnectionModal = ({ isOpen, onClose, setAddress, setWalletConnected }) => {
    const ref = useRef();

    const connectKeplrWallet = async () => {
        if (!window.keplr) {
            alert('Please install the Keplr wallet extension.');
            return;
        }

        try {
            const chainId = 'cosmoshub-4';
            await window.keplr.enable(chainId);
            const offlineSigner = window.getOfflineSigner(chainId);
            const accounts = await offlineSigner.getAccounts();

            if (accounts.length > 0) {
                setAddress(accounts[0].address);
                setWalletConnected(true);
                onClose();
            } else {
                alert('No accounts found in Keplr.');
            }
        } catch (error) {
            console.error('Failed to connect to Keplr:', error);
        }
    };

    const connectLeapWallet = async () => {
        if (!window.leap) {
            alert('Please install the Leap wallet extension.');
            return;
        }

        try {
            const chainId = 'cosmoshub-4';
            await window.leap.enable(chainId);
            const offlineSigner = window.leap.getOfflineSigner(chainId);
            const accounts = await offlineSigner.getAccounts();

            if (accounts.length > 0) {
                setAddress(accounts[0].address);
                setWalletConnected(true);
                onClose();
            } else {
                alert('No accounts found in Leap.');
            }
        } catch (error) {
            console.error('Failed to connect to Leap:', error);
        }
    };

    const handleOutsideClick = useCallback((event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onClose();
        }
    }, [onClose]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleOutsideClick, isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-lime-900 p-6  flex flex-col justify-center" ref={ref}>
                <h2 className="text-xl mb-4 text-white place-self-center">Connect Your Wallet</h2>
                <button 
                    onClick={connectKeplrWallet} 
                    className="bg-green-500 text-white px-4 py-2 flex flex-row items-center  mb-2 w-full cursor-pointer"
                >
                    <img src="./keplrLogo.png" alt="Keplr" className="h-8 w-8 mr-2" />
                    Keplr Wallet
                </button>
                <button 
                    onClick={connectLeapWallet} 
                    className="bg-blue-500 text-white px-4 py-2 flex flex-row items-center w-full cursor-pointer"
                >
                    <img src="./leaplogo.png" alt="Leap" className="h-8 w-8 mr-2" />
                    Leap Wallet
                </button>
                <button onClick={onClose} className="mt-4 flex justify-center text-lime-600 cursor-pointer">
                    Cancel
                </button>
            </div>
        </div>
    );
};

const App = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [walletConnected, setWalletConnected] = useState(false);
    const [address, setAddress] = useState('');
    const [wo7msInscriptions, setWo7msInscriptions] = useState([]);
    const [latestMints, setLatestMints] = useState([]);
    const [loadingLatestMints, setLoadingLatestMints] = useState(false);
    const [loadingBounties, setLoadingBounties] = useState(false);
    const [loadingCollectionStats, setLoadingCollectionStats] = useState(false);
    const [loadingOwnedInscriptions, setLoadingOwnedInscriptions] = useState(false);
    const [collectionStats, setCollectionStats] = useState(null);
    const [bounties, setBounties] = useState(null);
    const [floorPrice, setFloorPrice] = useState(null);
    const location = useLocation();
    const activeSection = location.pathname.replace('/', '') || 'wo7ms'; // Get active section from pathname

    const fetchCollectionStats = useCallback(async () => {
        const endpoint = 'https://new-api.asteroidprotocol.io/v1/graphql';
        const client = new GraphQLClient(endpoint);
        const query = gql`
            query GetCollectionStats {
                collection_stats(where: { collection: { name: { _eq: "WO7MS" } } }) {
                    floor_price
                    owners
                    supply
                }
            }`
        ;
        try {
            setLoadingCollectionStats(true);
            const data = await client.request(query);
            if (data.collection_stats && data.collection_stats.length > 0) {
                const stats = data.collection_stats[0];
                setCollectionStats(stats);
                setFloorPrice(stats.floor_price / 1000000);
            } else {
                console.error('No collection stats found.');
                setCollectionStats(null);
                setFloorPrice(null);
            }
        } catch (error) {
            console.error('Error fetching collection stats:', error);
        } finally {
            setLoadingCollectionStats(false);
        }
    }, []);

    const fetchBounties = useCallback(async () => {
        const endpoint = 'https://new-api.asteroidprotocol.io/v1/graphql';
        const client = new GraphQLClient(endpoint);
        const query = gql`
            query GetBounties {
                inscription(where: { current_owner: { _eq: "cosmos12hme9yhzrjmknhhug5k0n5t3vfuezwjyz8ryhp" } }) {
                    inscription_number
                    content_path
                    metadata
                    transaction { hash }
                    collection {
                        stats {
                            floor_price
                        }
                        name
                    }
                }
            }`
        ;
        try {
            setLoadingBounties(true);
            const data = await client.request(query);
            if (data.inscription && data.inscription.length > 0) {
                setBounties(data.inscription);
            } else {
                console.error('No bounties found.');
                setBounties(null);
            }
        } catch (error) {
            console.error('Error fetching bounties:', error);
        } finally {
            setLoadingBounties(false);
        }
    }, []);

    const fetchLatestMints = useCallback(async () => {
        const endpoint = 'https://new-api.asteroidprotocol.io/v1/graphql';
        const client = new GraphQLClient(endpoint);
        const latestQuery = gql`
            query GetLatestMints {
                inscription(order_by: { id: desc }, where: {
                    collection: { name: { _eq: "WO7MS" } }
                }) {
                    rarity { rarity_rank }
                    transaction { hash }
                    content_path
                    metadata
                }
            }`
        ;
        try {
            setLoadingLatestMints(true);
            const latestData = await client.request(latestQuery);
            setLatestMints(latestData.inscription);
        } catch (error) {
            console.error('Error fetching latest mints:', error);
        } finally {
            setLoadingLatestMints(false);
        }
    }, []);

    const fetchOwnedInscriptions = useCallback(async () => {
        if (!address) return;

        const endpoint = 'https://new-api.asteroidprotocol.io/v1/graphql';
        const client = new GraphQLClient(endpoint);
        const query = gql`
            query GetInscriptions($address: String!) {
                inscription(where: {
                    current_owner: { _eq: $address },
                    _and: [{
                        collection: { name: { _eq: "WO7MS" } }
                    }]
                }) {       
                    transaction { hash }
                    metadata
                    rarity { rarity_rank }
                    content_path
                }
            }`
        ;

        try {
            setLoadingOwnedInscriptions(true);
            const data = await client.request(query, { address });
            setWo7msInscriptions(data.inscription);
            
        } catch (error) {
            console.error('Error fetching owned inscriptions :', error);
        } finally {
            setLoadingOwnedInscriptions(false);
        }
    }, [address]);

    useEffect(() => {
        fetchCollectionStats();
        fetchLatestMints();
        fetchBounties();
        if (walletConnected) {
            fetchOwnedInscriptions();
        }
    }, [walletConnected, address, fetchOwnedInscriptions, fetchBounties, fetchCollectionStats, fetchLatestMints]);

    return (
        <div className="min-h-screen p-6">
            <Navbar activeSection={activeSection} />
            <Routes>
                <Route path="/wo7ms" element={<Wo7ms floorPrice={floorPrice} loadingCollectionStats={loadingCollectionStats} loadingLatestMints={loadingLatestMints} collectionStats={collectionStats} latestMints={latestMints}/>} />
                <Route path="/mint-raffle-prizes" element={<MintRafflePrizes collectionStats={collectionStats} loadingCollectionStats={loadingCollectionStats}/>} />
                <Route path="/mint-bounties" element={<MintBounties loadingBounties={loadingBounties} bounties={bounties}/>} />
                <Route path="/holders-zone" element={<HoldersZone setAddress={setAddress} address={address} walletConnected={walletConnected} 
                setWalletConnected={setWalletConnected} wo7msInscriptions={wo7msInscriptions} loadingOwnedInscriptions={loadingOwnedInscriptions} loadingLatestMints={loadingLatestMints} latestMints={latestMints} collectionStats={collectionStats} setModalOpen={setModalOpen}/>} />
                <Route path="/" element={<Wo7ms floorPrice={floorPrice} loadingCollectionStats={loadingCollectionStats} loadingLatestMints={loadingLatestMints} collectionStats={collectionStats} latestMints={latestMints}/>} />
            </Routes>
            {/* {loading ? (
                <p className="text-lg">Loading...</p>
            ) : null } */}
            <WalletConnectionModal 
                isOpen={modalOpen} 
                onClose={() => setModalOpen(false)} 
                setAddress={setAddress}
                setWalletConnected={setWalletConnected} 
            />
        </div>
    );
};


export default App;