import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const MintBounties = ({ loadingBounties, bounties }) => {
    const [isAtTop, setIsAtTop] = useState(true);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleScroll = () => {
        setIsAtTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="flex flex-col items-center justify-start min-h-screen p-6 border-2 border-lime-500 text-center" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
            <h2 className="text-5xl font-bold mb-4 text-lime-500">Mint Bounties</h2>
            <p className="text-xl md:text-2xl text-lime-500 mb-4">
                30 WO7MS from the collection are associated with a bounty since the beginning, meaning that if you hold the right WO7MS when the collection is minted out, you also get an OG inscription from another collection!
            </p>
            <p className="text-xl md:text-2xl text-lime-500 mb-4">
                WO7MS numbers are already associated with their bounties, and a proving document is kept on a private GitHub repository.
            </p>
            <p className="text-xl md:text-2xl text-white mb-4">
                If you hold a WO7MS, you can check if you caught one 
                <Link to="/holders-zone" className="animate-[pulse_1s_infinite] text-lime-500 font-bold text-2xl md:text-3xl hover:text-lime-600 cursor-pointer" title="Holders Only"> HERE</Link>.
            </p>
            <h3 className="text-3xl font-bold mb-4 text-lime-500">Here are all the bounties:</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
                {loadingBounties ? (
                    <p className="text-lg">Loading...</p>
                ) : (
                    Array.isArray(bounties) && bounties.length > 0 ? (
                        bounties.map(bounty => {
                            const bountyPrice = bounty.collection.stats.floor_price / 1000000;
                            return (
                                <div key={bounty.metadata.metadata.name} className="relative border text-lime-600 hover:scale-[1.02] text-xl border-lime-800 rounded-lg p-4 bg-lime-900 shadow-md">
                                    <img
                                        src={bounty.content_path}
                                        title='Click to see more details on Asteroid'
                                        className="w-full h-auto rounded-md cursor-pointer"
                                        alt="Wo7msCard"
                                        onClick={() => window.open(`https://asteroidprotocol.io/app/inscription/${bounty.transaction.hash}`, '_blank')}
                                    />
                                    <h4 className="font-semibold mt-3">{bounty.metadata.metadata.name}</h4>
                                    <h4 className="font-semibold">Inscription #<span style={{ fontFamily: 'Inter, sans-serif' }}>{bounty.inscription_number}</span></h4>
                                    <h4 className="font-semibold">Collection</h4>
                                    <h4 className="font-semibold">{bounty.collection.name}</h4>
                                    <h4 className="font-semibold text-2xl text-white">FLOOR <span style={{ fontFamily: 'Inter, sans-serif' }}>{bountyPrice ? bountyPrice : 'N/A'}</span>{' '}ATOM</h4>
                                </div>
                            );
                        })
                    ) : (
                        <p className="text-lg text-white">Issue fetching Bounties.</p>
                    )
                )}
            </div>
            <div className="flex justify-center w-full pl-16 pb-6 mt-6">
                <img 
                    src="./madlearnmore.gif" 
                    alt="Scientist Learn more" 
                    className="cursor-pointer max-w-64 md:max-w-80 hover:scale-[1.05] hover:ml-1 pb-3" 
                    title="Learn more about Asteroid Protocol" 
                    onClick={() => window.open('https://docs.asteroidprotocol.io/asteroid-protocol-docs/general-overview', '_blank')}
                />
            </div>
            {/* Back to Top Button */}
            {!isAtTop && (
                <h1 
                className="fixed bottom-2 md:bottom-10 right-10 text-2xl  bg-black text-white rounded-full p-3 shadow-lg hover:bg-lime-900 transition 
                 rounded-full border-2 border-solid border-lime-600 cursor-pointer"
                onClick={scrollToTop}
                title="Back to Top"
            >
                ↑
            </h1>
            )}
        </div>
    );
};

export default MintBounties;
