import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ activeSection }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className=" border-solid border-t-2 border-x-2 border-lime-500 p-4 flex items-center justify-between" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
            <img 
                className="max-w-20 cursor-pointer" 
                onClick={() => window.open('https://x.com/CosmosWorms', 'blank')}
                title='WO7MS on X'
                src="./WO7MSLOGO.png" 
                alt="logo"
            />

            
            {/* Desktop Menu */}
            <div className="hidden md:flex md:items-center md:justify-center lg:flex lg:justify-between lg:text-2xl lg:mr-12 text-lime-700">
                <ul className="flex space-x-8">
                    {['wo7ms', 'mint-raffle-prizes', 'mint-bounties', 'holders-zone'].map((section) => (
                        <li key={section}>
                            <Link 
                                to={`/${section}`} 
                                className={`hover:text-white cursor-pointer ${activeSection === section ? 'text-white' : 'text-lime-600'}`}
                            >
                                {section.replace(/-/g, ' ').toUpperCase()}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Mobile Menu Button */}
            <button 
                className="md:hidden text-lime-600 focus:outline-none" 
                onClick={toggleMenu}
            >
                <svg 
                    className="w-8 h-8" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
            </button>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="md:hidden absolute top-16 left-1 right-1 bg-black border-2 border-lime-600">
                <ul className="flex flex-col space-y-4 p-4 text-lime-700">
                    {['wo7ms', 'mint-raffle-prizes', 'mint-bounties', 'holders-zone'].map((section) => (
                        <li key={section}>
                            <Link 
                                to={`/${section}`} 
                                className={`hover:text-white ${activeSection === section ? 'text-white' : 'text-lime-600'}`}
                                onClick={toggleMenu} // Close menu on link click
                            >
                                {section.replace(/-/g, ' ').toUpperCase()}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            )}
        </nav>
    );
};

export default Navbar;
